<template>
    <div class="box">
        <div class="box-con">
            <div class="box_con_cen">
                <h1>账号登录</h1>
                <div>Hello! 欢迎登录云云问车官网!</div>
                <el-form :model="form">
                    <el-form-item label="用户名">
                        <el-input v-model="form.username">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input v-model="form.password" show-password></el-input>
                    </el-form-item>
                    <div class="forget" @click="forget">忘记密码?</div>
                </el-form>
                <div class="btn">
                    <el-button type="primary" @click="Login" style="width:100%">登录</el-button>
                </div>
            </div>

        </div>
        <el-dialog :visible.sync="dialog.show" width="400px" title="重置密码">
            <el-form :model="reset">
                <div class="div" v-if="step==1">
                    <el-form-item>
                        <el-input v-model="reset.teleNumber" placeholder="请输入手机号"></el-input>
                        <div class="forget" @click="verCode">获取验证码</div>
                    </el-form-item>

                    <el-form-item>
                        <el-input v-model="reset.code" placeholder="请输入验证码"></el-input>
                    </el-form-item>
                </div>
                <div v-else-if="step==2">
                    <el-form-item>
                        <el-input v-model="reset.newPassword" placeholder="新密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="reset.confirmPassword" placeholder="确认密码" show-password></el-input>
                    </el-form-item>
                </div>

                <div class="div" style="margin-left:30%">
                    <el-button @click="dialog.show=false">取消</el-button>
                    <el-button type="primary" @click="step+=1" v-if="step==1">下一步</el-button>
                    <el-button type="primary" @click="complete" v-else-if="step==2">完成</el-button>

                </div>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
// import {
//     setToken
// } from "@/utils/auth";
// import {
//     login
// } from "@/api/login"
export default {
    name: "Login",
    data() {
        return {
            step: 1,
            reset: {
                teleNumber: "",
                newPassword: "",
                confirmPassword: "",
                code: "",
            },
            form: {
                username: "",
                password: "",
            },
            dialog: {
                show: false,
            },
        };
    },
    created() {},
    computed: {},
    methods: {
        Login() {
            // login(this.form).then(res => {
            //     setToken(res.data.token);
            //     this.$message.success(res.msg)
            //     this.$router.push("/")
            // }).catch(() => {
            //     this.$message.error("用户名或密码错误!")
            // })
        },
        forget() {
            this.dialog.show = true;
        },
        verCode() {},
        complete() {
            if (this.reset.newPassword == this.reset.confirmPassword) {
                this.$message.success("修改成功");
                this.dialog.show = false;
                this.step = 1;
            } else {
                this.$message.error("两次密码不一致!!");
            }
        },
    },
};
</script>
<style lang="less" scoped>
.forget:hover {
    cursor: pointer;
}

.forget {
    float: right;
    color: #409eff;
}

.box {
    width: 100%;
    height: 100%;
    .box-con {
        // width: 45%;
        display: inline-block;
        height: 100%;
        // float: right;
        .box_con_cen {
            width: 100%;
        }
        // .form {
        //     // background-color: black;
        //     display: inline-block;
        //     position: relative;
        //     transform: translateX(-50%) translateY(-50%);
        //     left: 50%;
        //     top: 40%;
        //     width: 360px;

        //     h1 {
        //         font-size: 24px;
        //     }

        //     form {
        //         font-weight: 100;
        //         margin-top: 20px;
        //     }

        //     .btn {
        //         margin-top: 40px;
        //         width: 100%;
        //     }
        // }
    }
}
</style>